import React, { useEffect, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { updateComplaintApi } from "../components/APIs/Api";
import { toast } from "react-hot-toast";
import { StaticGoogleMap, Marker } from "react-static-google-map";
import { useLocation, useHistory } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import ShowImage from "../modal/ShowImage";
import defaltImage from "../../images/defaulImg.jpg";
import Carousel from "react-bootstrap/Carousel";

import moment from "moment";
import map2 from "../../images/pin-location-4355.svg";

export default function UserDetails(props) {
  const [loader, setLoader] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: "", lng: "" });
  const data = props.location.state.data;
  const api = props.location.state.api;
  const [selectedImage, setSelectedImage] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onImageError = (e) => {
    e.target.src = defaltImage;
  };
  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  async function blockUser(id, status) {
    setLoader(true);
    try {
      const res = await updateComplaintApi(id, status);
      if (res?.data?.data?.status === "Resolve") {
        toast.success("Complaint Resolved successfully");
      } else {
        toast.error("Complaint Rejected successfully");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      if (typeof window[api] === "function") {
        window[api](); // Call the API function
      }
      props.history.goBack();
      setLoader(false);
    }
  }

  console.log(data);

  useEffect(() => {
    if (data && data.location && data.location.coordinates) {
      const lat = parseFloat(data.location.coordinates[0]);
      const lng = parseFloat(data.location.coordinates[1]);
      if (!isNaN(lat) && !isNaN(lng)) {
        setMapCenter({ lat, lng });
      } else {
        console.error("Invalid coordinates:", data.location.coordinates);
      }
    }
  }, [data]);

  return (
    <>
      <div className="">
        <div className="page-titles">
          <h4>Incidence Details</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="user-management">Incidence List</Link>
            </li>

            <li className="breadcrumb-item active">
              <Link>Incidence Details</Link>
            </li>
          </ol>
        </div>

        <div className="d-flex justify-content-end mb-2">
          {data?.status !== "Deleted" && (
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="btn-sm"
              >
                Change Status
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <>
                  {data?.status !== "close" && (
                    <Dropdown.Item onClick={() => blockUser(data?._id, true)}>
                      Closed
                    </Dropdown.Item>
                  )}
                  {data?.status !== "Rejected" && (
                    <Dropdown.Item onClick={() => blockUser(data?._id, false)}>
                      Reject
                    </Dropdown.Item>
                  )}
                </>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-xl-3 col-xxl-3 col-lg-12 col-md-12">
          <div className="row">
            <div className="col-xl-12 col-lg-6 ">
              <div className="card h-auto">
                <div className="card-body text-center">
                  <img
                    src={`http://165.227.65.238:5000/${data?.user?.image}`}
                    width={150}
                    height={150}
                    className="rounded-circle mb-4"
                    alt="avatar1"
                    onError={onImageError}
                  />
                  <h4 className="m-0 text-black font-w600">
                    {data?.user?.name}
                  </h4>
                  <small>{data?.user?.email}</small>
                  <small>{data?.user?.phoneNumber}</small>

                  {/* <Link to="/order" className="btn btn-primary light btn-xs">
                    Customer
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-lg-6">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="card-title">Status History</h4>
                </div>
                <div className="card-body">
                  {data?.status === "open" && (
                    <div className="widget-timeline-icon ">
                      <ul className="timeline">
                        <li className="process-status">
                          <div className="icon bg-primary" />
                          <h4 className="mb-2">{"Open"}</h4>
                          <p className="fs-15 mb-0 ">Wait..</p>
                        </li>
                        <li className="process-status">
                          <div className="icon bg-primary" />
                          <h4 className="mb-2">{"Closed"}</h4>
                        </li>
                        <li className="process-status">
                          <div className="icon bg-primary" />
                          <h4 className="mb-2">{"Rejected"}</h4>
                        </li>
                        <li className="">
                          <div className="icon bg-primary" />
                          <h4 className="mb-2">{"Deleted"}</h4>
                        </li>
                      </ul>
                    </div>
                  )}
                  {data?.status === "close" && (
                    <div className="widget-timeline-icon ">
                      <ul className="timeline">
                        <li className="">
                          <div className="icon bg-primary" />
                          <h4 className="mb-2">{"Open"}</h4>
                        </li>
                        <li className="process-status">
                          <div className="icon bg-primary" />
                          <h4 className="mb-2">{"Closed"}</h4>
                          <p className="fs-15 mb-0 ">
                            {moment(data?.date).format("llll")}
                          </p>
                        </li>
                        <li className="process-status">
                          <div className="icon bg-primary" />
                          <h4 className="mb-2">{"Rejected"}</h4>
                        </li>
                        <li className="">
                          <div className="icon bg-primary" />
                          <h4 className="mb-2">{"Deleted"}</h4>
                        </li>
                      </ul>
                    </div>
                  )}
                  {data?.status === "Rejected" && (
                    <div className="widget-timeline-icon ">
                      <ul className="timeline">
                        <li className="">
                          <div className="icon bg-primary" />
                          <h4 className="mb-2">{"Open"}</h4>
                        </li>
                        <li className="">
                          <div className="icon bg-primary" />
                          <h4 className="mb-2">{"Closed"}</h4>
                        </li>
                        <li className="process-status">
                          <div className="icon bg-primary" />
                          <h4 className="mb-2">{"Rejected"}</h4>
                          <p className="fs-15 mb-0 ">
                            {moment(data?.date).format("llll")}
                          </p>
                        </li>
                        <li className="open-status">
                          <div className="icon bg-primary" />
                          <h4 className="mb-2">{"Deleted"}</h4>
                        </li>
                      </ul>
                    </div>
                  )}
                  {data?.status === "Deleted" && (
                    <div className="widget-timeline-icon ">
                      <ul className="timeline">
                        <li className="">
                          <div className="icon bg-primary" />
                          <h4 className="mb-2">{"Open"}</h4>
                        </li>
                        <li className="">
                          <div className="icon bg-primary" />
                          <h4 className="mb-2">{"Closed"}</h4>
                        </li>
                        <li className="">
                          <div className="icon bg-primary" />
                          <h4 className="mb-2">{"Rejected"}</h4>
                        </li>
                        <li className="open-status">
                          <div className="icon bg-primary" />
                          <h4 className="mb-2">{"Deleted"}</h4>
                          <p className="fs-15 mb-0 ">
                            {moment(data?.date).format("llll")}
                          </p>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-xxl-9 col-lg-12 col-md-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body pt-2">
                  <div className="table-responsive ">
                    <div className="text-black pt-2">
                      <div className="pt-2 pl-2 font-weight-bold">
                        <p className="m-0">{data?.service}</p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center p-2">
                        <p className="m-0 text-muted">{data?.describe}</p>
                      </div>
                      <div className="d-flex align-items-center p-2">
                        <p className="m-0 " style={{ fontWeight: "550" }}>
                          Posted :
                        </p>
                        <span className="ml-1">
                          {" "}
                          {moment(data?.date).format("ll")}
                          {data?.time}
                        </span>
                      </div>
                      {/* // <Card className="p-3 mb-0"> */}
                      <div
                        className="d-flex justify-content-evenly p-2 complaint-image"
                        style={{ height: "120px", width: "120px", gap: "20px" }}
                      >
                        {data?.files?.map((image) => (
                          <img
                            src={`http://165.227.65.238:5000/${image?.file}`}
                            style={{
                              objectFit: "cover",
                              height: "100%",
                              width: "100%",
                            }}
                            onClick={() => openModal(image?.file)}
                          />
                        ))}
                      </div>
                      {/* </Card>  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  {mapCenter?.lat && (
                    <div style={{ height: "35vh", width: "100%" }}>
                      <StaticGoogleMap
                        size="600x600"
                        apiKey="AIzaSyDVBOEhuWVXo697R_RniZYQ4v9uASfMJt4"
                      >
                        <Marker
                          location={{ lat: 30.741482, lng: 76.768066 }}
                          color="blue"
                          label="P"
                          size="tiny"
                          iconURL="https://gasdeverao.cocacola.com.br/assets/favicons/favicon-32x32.png"
                        />
                      </StaticGoogleMap>
                    </div>
                  )}
                  <div className=" p-1 d-flex align-item-center">
                    <i className="flaticon-381-location-4 location-icon" />
                    <strong className="mt-3 ml-2">{data?.address}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ShowImage
          show={isModalOpen}
          onHide={() => setIsModalOpen(false)}
          image={selectedImage}
        />
      )}
    </>
  );
}
